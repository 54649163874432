import React, { Component } from 'react';

export default class FAQ extends Component {
    render() {
        return <div className="section padding-top-bottom-big  background-gradient-dark" id="faq">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title-wrap text-center">
                            <div className="back-title">faq</div>
                            <h3 className="text-white">Questions and Answers</h3>
                            <p className="color-pr">Listed questions and answers, all supposed to be commonly asked in some context, and pertaining to a particular topic.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div id="accordion" className="accordion-style">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <a className="text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is an NFT
                                    </a>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body">
                                        <h6 className="text-white">What is an NFT</h6>
                                        <p className="text-grey">
                                            They are one of a kind digital items that users can buy, own, and trade. Some NFTs only purpose is to exist as artwork. Some offer additional utility like games, metaverse access, entry to gated sections of websites, or participation in an event.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <a className="collapsed text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What is Metamask
                                    </a>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div className="card-body">
                                        <h6 className="text-white">What is Metamask</h6>
                                        <p className="text-grey">
                                            Metamask is a safe Cryptocurrency wallet where you can store Cryptocurrency and NFTs. It is required to purchase and mint your NFT. Download <a href="https://metamask.io/" target="_blank">here</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingThree">
                                    <a className="collapsed text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Mining Our NFT
                                    </a>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="card-body">
                                        <h6 className="text-white">Mining Our NFT</h6>
                                        <p className="text-grey">In order to get one of our mining NFTS, just simply go to our website and click the start mining button. Leave the webpage open and mining until you are lucky enough to generate a mining rig NFT. It may take minutes, or hours, or days to discover and Mint your NFT miner.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 mt-md-0">
                        <div id="accordion-snd" className="accordion-style">
                            <div className="card">
                                <div className="card-header" id="headingFour">
                                    <a className="text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        Where will my Mining NFT go?
                                    </a>
                                </div>

                                <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordion-snd">
                                    <div className="card-body">
                                        <h6 className="text-white">Where will my Mining NFT go?</h6>
                                        <p className="text-grey">
                                            Your mining NFT will be sent to the wallet you connected / purchased from. It will be viewable on our website and official Opensea collection.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingFive">
                                    <a className="collapsed text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        What can I do with my Mining NFT
                                    </a>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion-snd">
                                    <div className="card-body">
                                        <h6 className="text-white">What can I do with my Mining NFT</h6>
                                        <p className="text-grey">
                                            Once you have your Metamask wallet and your mining NFT plugged in you can mine the RIGS token to your Metamask wallet.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingSix">
                                    <a className="collapsed text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        How much can I make?
                                    </a>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion-snd">
                                    <div className="card-body">
                                        <h6 className="text-white">How much can I make?</h6>
                                        <p className="text-grey">
                                            It is based on the rarity of your mining NFT (Legendary Miners will mine more tokens than a Common). It also depends on the value of the RIGS token. The more you spread the word about the Mining NFT and RIGS token the more value-able the NFT and token/coin become.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" id="headingSix">
                                    <a className="collapsed text-white background-dark-blue-2" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        How do I get Paid?
                                    </a>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion-snd">
                                    <div className="card-body">
                                        <h6 className="text-white">How do I get Paid?</h6>
                                        <p className="text-grey">
                                            Click the get paid button and you will receive payment in RIGS tokens to your metamask wallet that you can swap or sell.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
