import React, { Component } from 'react';

export default class Perks extends Component {
    render() {
        return <div className="section padding-top-bottom-big" id="roadmap">
            <div className="background-parallax" style={{
                backgroundImage: "url('img/parallax-dark.jpg')"
            }} data-enllax-ratio=".5" data-enllax-type="background" data-enllax-direction="vertical"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title-wrap text-center">
                            <div className="back-title">roadmap</div>
                            <h3 className="text-white">Making the most<br />out of unused.</h3>
                            <p className="color-pr">The Coeus token sale events are unique as they offer tiered bonuses which include increasing token price and lifetime discounts.</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="customNavigation">
                            <a className="prev-roadmap dark"><i className="fa fa-angle-left"></i></a>
                            <a className="next-roadmap dark"><i className="fa fa-angle-right"></i></a>
                        </div>
                        <div id="owl-roadmap" className="owl-carousel owl-theme">
                            <div className="item dark">
                                <div className="roadmap-box background-dark-blue-2">
                                    <p className="text-grey">
                                        Comic book development, one page per month after secondary market release.
                                    </p>
                                </div>
                            </div>

                            <div className="item dark">
                                <div className="roadmap-box background-dark-blue-2">
                                    <p className="text-grey">
                                    Win printed copies of our comic book once completed.
                                    </p>
                                </div>
                            </div>

                            <div className="item dark">
                                <div className="roadmap-box background-dark-blue-2">
                                    <p className="text-grey">
                                    Short story or Novella based on our lore.
                                    </p>
                                </div>
                            </div>

                            <div className="item dark">
                                <div className="roadmap-box background-dark-blue-2">
                                    <p className="text-grey">
                                    Faction wars to be announced.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>;
    }
}
