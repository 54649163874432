import React, { Component } from 'react';
import FAQ from './FAQ';
import Footer from './Footer';
import Header from './Header';
import Perks from './Perks';
import Roadmap from './Roadmap';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import Story from './Story';

export default class Main extends Component {
  render() {
    return <div>
      <Header />

      <SectionOne />
      
    </div>;
  }
}
