import React, { Component } from 'react';

export default class Footer extends Component {
	render() {
		return <div>
			<div className="section ">
				<div className="section py-4 background-dark-blue-4">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 footer text-center text-lg-left">
								<p>Copyright © 2022,  <a href="Nots.art"> Minting NFT</a></p>
							</div>
							<div className="col-lg-6 footer mt-4 mr-auto mt-lg-0 mr-lg-0 text-center text-lg-right">
								<a className="app-btn mx-2 mr-lg-3" href="#"><i className="fa fa-twitter"></i></a>
								<a className="app-btn mx-2 mr-lg-3" href="#"><i className="fa fa-linkedin"></i></a>
								<a className="app-btn mx-2 mr-lg-3" href="#"><i className="fa fa-facebook"></i></a>
								<a className="app-btn mx-2 mr-lg-3" href="#"><i className="fa fa-git"></i></a>
								<a className="app-btn mx-2 mr-lg-3" href="#"><i className="fa fa-pinterest-p"></i></a>
								<a className="app-btn mx-2 mr-lg-0" href="#"><i className="fa fa-paper-plane"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="scroll-to-top">to top</div>
		</div>;
	}
}
