import logo from './logo.svg';
import './App.css';
import Main from './screens/Main';

function App() {
  return (
    <Main/>
  );
}

export default App;
