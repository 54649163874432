import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, push } from "firebase/database";
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'


const moment = require('moment')


export default class SectionOne extends Component {

    state = {
        presaleLimit: 250,
        completedSlots: 0,
        loading: false,
        email: '',
        walletAddress: '',
        addedAddresses : []
    }

    constructor(props) {
        super(props);
        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
    }

    componentDidMount() {

        console.clear()

        this.intFirebase();
        this.getPresaleCount();

    }

    showSuccessAlert() {
        const alertId = StatusAlertService.showSuccess('Default success alert!');
        this.setState({ alertId });
    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    intFirebase() {
        try {
            const firebaseConfig = {
                apiKey: "AIzaSyA8bFSih3eQg0LhemPH9lS1gUwa0_MOMAY",
                authDomain: "cyber-heros-presale.firebaseapp.com",
                projectId: "cyber-heros-presale",
                storageBucket: "cyber-heros-presale.appspot.com",
                messagingSenderId: "654936011532",
                appId: "1:654936011532:web:de23e07b539df7602a4f29"
            };

            const app = initializeApp(firebaseConfig);
        } catch (error) {
            console.log(error);
        }
    }

    getPresaleCount() {
        try {
            const db = getDatabase();
            const starCountRef = ref(db, 'whitelist');
            onValue(starCountRef, (snapshot) => {

                let list = []
                
                snapshot.forEach(function(childsnap){
                    const data = childsnap.val();
                    list.push(data)
                })

                this.setState({
                    addedAddresses : list,
                    completedSlots : list.length
                });

                console.log(list.length);

              });

        } catch (error) {
            console.log('getPresaleCount', error);
        }
    }

    savePresale() {
        try {

            const { email, walletAddress } = this.state

            if (!email) {
                StatusAlertService.showError('Invalid Email Address');
                return;
            }
            if(!this.validateEmail(email)){
                StatusAlertService.showError('Invalid Email Address');
                return;
            }
            if(!walletAddress){
                StatusAlertService.showError('Invalid Wallet Address');
                return;
            }


            const db = getDatabase();

            push(ref(db, 'whitelist'), {
                email: email,
                walletAddress: walletAddress,
                presale: 1,
                date : moment().format('L')
            });

            StatusAlertService.showSuccess('Thank you for joining the whitelisting.');

            this.setState({
                email : '',
                walletAddress : ''
            });



        } catch (error) {
            console.log('savePresale', error);
        }
    }

    didSelectRegister() {

        const { presaleLimit, completedSlots } = this.state

        if (completedSlots < presaleLimit) {
            this.savePresale();
        }
    }

    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };


    render() {

        const { email, walletAddress } = this.state

        return <div className="section full-height height-auto-lg hide-over background-dark-blue-3">
            <div className="hero-center-wrap relative-on-lg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center text-lg-left parallax-fade-top align-self-center z-bigger">
                            <h2 className="text-white">Reserve Your Slot Now</h2>
                            <p className="mt-3 mb-4 pb-3 font-weight-normal text-white">
                                Please reserve your slot now.
                            </p>

                            {/* <a className="btn btn-primary ml-lg-0 js-tilt" href="#" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Whitepaper</span></a>
                            <a className="btn btn-primary js-tilt" href="#app" data-gal="m_PageScroll2id" data-ps2id-offset="68" role="button" data-tilt-perspective="300" data-tilt-speed="700" data-tilt-max="24"><span>Download App</span></a> */}

                            <div className="">
                                <input className="form-control text-left"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={e => {
                                        this.setState({
                                            email: e.target.value
                                        })
                                    }}
                                    type="email" />

                                <input className="form-control text-left mt-2"
                                    placeholder="Enter your wallet address"
                                    value={walletAddress}
                                    onChange={e => {
                                        this.setState({
                                            walletAddress: e.target.value
                                        })
                                    }}
                                    type="text" />

                                <button type="submit"
                                    onClick={() => {
                                        this.didSelectRegister();
                                    }}
                                    className="btn btn-primary m-0 js-tilt mt-4"
                                    data-tilt-perspective="300"
                                    data-tilt-speed="700" data-tilt-max="24"><span>Register Now</span></button>
                            </div>
                            <p className="text-left text-white mb-0"><small></small></p>
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0">
                            <Carousel
                                autoPlay={true}
                                centerMode={false}
                                infiniteLoop
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}>
                                <div>
                                    <img src="./img.jpg" />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <StatusAlert/>
        </div>
            ;
    }
}
